<template>
  <div class="container">
    <div class="banner">
      <swiper ref="mySwiper" class="banner" :options="swiperOption" :not-next-tick="true">
        <swiper-slide
          v-for="items in datalJson.imgList"
          :key="items.bannerId"
        >
          <img :src="items.bannerImage">
        </swiper-slide>
        <div v-show="datalJson.imgList.length > 1" slot="button-prev" class="swiper-button-prev" />
        <div v-show="datalJson.imgList.length > 1" slot="button-next" class="swiper-button-next" />
      </swiper>
    </div>
    <div class="paddingPub padTopHead promotionBox">
      <!-- <div class="topbanner">
        <swiper ref="mySwiper" :options="swiperOption" :not-next-tick="true">
          <swiper-slide
            v-for="items in datalJson.imgList"
            :key="items.bannerId"
          >
            <img :src="items.bannerImage">
          </swiper-slide>
          <div v-show="datalJson.imgList.length > 1" slot="button-prev" class="swiper-button-prev" />
          <div v-show="datalJson.imgList.length > 1" slot="button-next" class="swiper-button-next" />
        </swiper>
      </div> -->
      <div class="contentBox">
        <div class="title">{{ $lan === 'tc' ? datalJson.publicTitle : datalJson.simplifiedTitle }}</div>
        <div v-html="$lan === 'tc' ? datalJson.publicContent : datalJson.simplifiedContent" class="content" />
      </div>
    </div>
    <div class="address">
      <img src="@/assets/images/Map.png">
    </div>
  </div>
</template>
<script>
import { aboutUs } from '../utils/request'
export default {
  name: 'About',
  data() {
    return {
      datalJson: {},
      swiperOption: {
        autoplay: 8000,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  mounted() {
    this.getPromotionDetailJson()
  },
  methods: {
    async getPromotionDetailJson() {
      const result = await aboutUs()
      if (result.head.success) {
        this.datalJson = result.body
      } else {
        this.$message.error(result.head.message)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.banner{
  height: 100vh;
  img{
    margin-top: 0;
  }
}
.promotionBox {
  position: relative;
}
.contentBox{
  color: #303030;
  text-align: center;
  .title{
    font-size: 24px;
    color: #000000;
    font-weight: bold;
    margin-bottom: 4%;
  }
  .content {
    font-weight: bold;
    ul {
      margin: 28px 0;
      color: #000000;
    }
    .ps{
      margin-top: 40px;
    }
  }
}
.address{
  margin-top: 4%;
}
@media screen and (max-width: 1680px) {
  .banner{
    height: 80vh;
  }
}
@media screen and (max-width: 996px) {
  .banner{
    height: 62vh;
  }
}
@media screen and (max-width: 768px) {
  .banner{
    height: 40vh;
  }
}
@media screen and (max-width: 576px) {
  .banner{
    height: 28vh;
  }
  .promotionBox{
    &::after{
      width: 0;
    }
  }
}
</style>
